var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F A B 6B"
    },
    B: {
      "2": "C K L G M N O",
      "164": "P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H"
    },
    C: {
      "2": "0 1 2 3 4 5 6 7 8 9 7B qB I r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U 8B 9B",
      "66": "V W",
      "257": "Y Z a b e f g h i j k l m n o p q c H uB vB",
      "772": "X"
    },
    D: {
      "2": "I r J D E F A B C K L G M N O s t",
      "164": "0 1 2 3 4 5 6 7 8 9 u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC"
    },
    E: {
      "2": "I r CC wB DC",
      "132": "A B C K xB nB oB yB",
      "164": "J D E F EC FC GC",
      "516": "L G HC IC zB 0B 1B 2B pB 3B JC KC"
    },
    F: {
      "2": "F B C LC MC NC OC nB 4B PC oB",
      "164": "0 1 2 3 4 5 6 7 8 9 G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b"
    },
    G: {
      "2": "wB QC 5B RC",
      "132": "XC YC ZC aC bC cC dC eC fC gC",
      "164": "E SC TC UC VC WC",
      "516": "hC iC jC zB 0B 1B 2B pB 3B"
    },
    H: {
      "2": "kC"
    },
    I: {
      "2": "qB I lC mC nC oC 5B",
      "164": "H pC qC"
    },
    J: {
      "2": "D",
      "164": "A"
    },
    K: {
      "2": "A B C nB 4B oB",
      "164": "d"
    },
    L: {
      "164": "H"
    },
    M: {
      "257": "c"
    },
    N: {
      "2": "A B"
    },
    O: {
      "164": "rC"
    },
    P: {
      "164": "I sC tC uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C"
    },
    Q: {
      "164": "yB"
    },
    R: {
      "164": "5C"
    },
    S: {
      "2": "6C"
    }
  },
  B: 5,
  C: "CSS image-set"
};
export default exports;